var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vx-card",
    {
      attrs: {
        title:
          _vm.id === null ? _vm.$t("classes_create") : _vm.$t("classes_edit"),
      },
    },
    [
      _vm.id === null
        ? _c("PlanUsageAlert", { attrs: { target: "classes" } })
        : _vm._e(),
      _c(
        "vs-tabs",
        { attrs: { color: _vm.colorx } },
        [
          _c("vs-tab", { attrs: { label: _vm.$t("classes") } }, [
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full required",
                    attrs: { label: _vm.$t("classes_title"), name: "title" },
                    model: {
                      value: _vm.title,
                      callback: function ($$v) {
                        _vm.title = $$v
                      },
                      expression: "title",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("title"),
                          expression: "errors.has('title')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("title")))]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      label: _vm.$t("description"),
                      name: "description",
                    },
                    model: {
                      value: _vm.description,
                      callback: function ($$v) {
                        _vm.description = $$v
                      },
                      expression: "description",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("description"),
                          expression: "errors.has('description')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("description")))]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c(
                    "vx-input-group",
                    [
                      _c("vs-input", {
                        staticClass: "required",
                        attrs: {
                          disabled: "",
                          value: _vm.discipline.title,
                          label: _vm.$t("discipline"),
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("discipline_id"),
                              expression: "errors.has('discipline_id')",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v(_vm._s(_vm.errors.first("discipline_id")))]
                      ),
                      _c("template", { slot: "append" }, [
                        _c(
                          "div",
                          {
                            staticClass: "append-text btn-addon flex items-end",
                          },
                          [
                            _c("vs-button", {
                              attrs: {
                                color: "primary",
                                type: "border",
                                icon: "search",
                                title: _vm.$t("buscar"),
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showDisciplineGrid = true
                                },
                              },
                            }),
                            _c("vs-button", {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "classes.discipline.create",
                                  expression: "'classes.discipline.create'",
                                },
                              ],
                              attrs: {
                                color: "primary",
                                type: "border",
                                icon: "add",
                                title: _vm.$t("incluir"),
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showDisciplineForm = true
                                },
                              },
                            }),
                            _c("vs-button", {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "classes.discipline.clear",
                                  expression: "'classes.discipline.clear'",
                                },
                              ],
                              attrs: {
                                color: "primary",
                                type: "border",
                                icon: "clear",
                                title: _vm.$t("action.clear"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setDiscipline(undefined, "")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c(
                    "label",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: {
                            content:
                              "<div style='max-width: 200px'>Permite que um aluno se inscreva nesta turma utilizando o código da turma, caso contrário ele poderá se inscrever somente se for convidado através de um link que ele receberá por email.</div>",
                            delay: { show: 500, hide: 100 },
                          },
                          expression:
                            "{\n            content: `<div style='max-width: 200px'>Permite que um aluno se inscreva nesta turma utilizando o código da turma, caso contrário ele poderá se inscrever somente se for convidado através de um link que ele receberá por email.</div>`,\n            delay: { show: 500, hide: 100 }\n          }",
                        },
                      ],
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("permitir-inscricao-com-codigo-da-turma")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("br"),
                  _c(
                    "vs-radio",
                    {
                      staticClass: "mt-2",
                      attrs: { "vs-name": "allow_all_radio", "vs-value": true },
                      model: {
                        value: _vm.allow_all,
                        callback: function ($$v) {
                          _vm.allow_all = $$v
                        },
                        expression: "allow_all",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("sim")) + " ")]
                  ),
                  _c(
                    "vs-radio",
                    {
                      staticClass: "ml-4 mt-2",
                      attrs: {
                        "vs-name": "allow_all_radio",
                        "vs-value": false,
                      },
                      model: {
                        value: _vm.allow_all,
                        callback: function ($$v) {
                          _vm.allow_all = $$v
                        },
                        expression: "allow_all",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("nao")) + " ")]
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("allow_all"),
                          expression: "errors.has('allow_all')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("allow_all")))]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "vx-col w-full mt-4" }, [
                _c(
                  "fieldset",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.top",
                        value: {
                          content:
                            "O que mostrar ao aluno na tela de detalhamento da turma.",
                          delay: { show: 1000, hide: 100 },
                        },
                        expression:
                          "{\n              content: 'O que mostrar ao aluno na tela de detalhamento da turma.',\n              delay: { show: 1000, hide: 100 }\n            }",
                        modifiers: { top: true },
                      },
                    ],
                    staticClass: "p-2",
                  },
                  [
                    _c("legend", [
                      _vm._v(_vm._s(_vm.$t("detalhamento-da-turma"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex flex-row gap-2" },
                      [
                        _c(
                          "vs-checkbox",
                          {
                            model: {
                              value: _vm.show_teachers,
                              callback: function ($$v) {
                                _vm.show_teachers = $$v
                              },
                              expression: "show_teachers",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("listar-professores")))]
                        ),
                        _c(
                          "vs-checkbox",
                          {
                            model: {
                              value: _vm.show_students,
                              callback: function ($$v) {
                                _vm.show_students = $$v
                              },
                              expression: "show_students",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("listar-alunos")))]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "float-right",
                      attrs: { type: "border" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v(_vm._s(_vm.$t("action.cancel")))]
                  ),
                  _c(
                    "vs-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: "classes.create",
                          expression: "'classes.create'",
                        },
                      ],
                      staticClass: "float-right mr-2",
                      attrs: { disabled: !_vm.validateForm },
                      on: { click: _vm.create },
                    },
                    [_vm._v(_vm._s(_vm.$t("action.save")))]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm.id !== null
            ? _c("vs-tab", { attrs: { label: _vm.$t("students") } }, [
                _c(
                  "div",
                  {
                    staticClass: "vx-row mb-6",
                    staticStyle: { "padding-top": "30px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vx-card", [
                          _c(
                            "div",
                            { staticClass: "vx-row flex items-center" },
                            [
                              _c(
                                "div",
                                { staticClass: "vx-col sm:w-3/12 w-full" },
                                [
                                  _c("label", [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.$t("codigo-convite"))),
                                    ]),
                                    _vm._v(" " + _vm._s(_vm.invite_code) + " "),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "vx-col sm:w-9/12 w-full" },
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      directives: [
                                        {
                                          name: "clipboard",
                                          rawName: "v-clipboard:copy",
                                          value: _vm.invite_code,
                                          expression: "invite_code",
                                          arg: "copy",
                                        },
                                        {
                                          name: "clipboard",
                                          rawName: "v-clipboard:success",
                                          value: _vm.onSucceddCopy,
                                          expression: "onSucceddCopy",
                                          arg: "success",
                                        },
                                        {
                                          name: "clipboard",
                                          rawName: "v-clipboard:error",
                                          value: _vm.onErrorCopy,
                                          expression: "onErrorCopy",
                                          arg: "error",
                                        },
                                      ],
                                      staticClass: "footer-button",
                                      attrs: {
                                        type: "filled",
                                        icon: "content_copy",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("copiar")))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "vx-card",
                          {
                            staticClass: "mt-4",
                            attrs: { title: _vm.$t("convidar-alunos") },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "grid grid-cols-10 w-full gap-2" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "fixAutoComplete col-span-10 md:col-span-8",
                                  },
                                  [
                                    _c("emails-input", {
                                      attrs: {
                                        validation: [
                                          {
                                            classes: "valid-email-or-user",
                                            rule: function (ref) {
                                              var student_id = ref.student_id
                                              var text = ref.text

                                              return (
                                                !this$1.$utils.validation.email(
                                                  text
                                                ) && this$1.isEmpty(student_id)
                                              )
                                            },
                                          },
                                        ],
                                        autocompleteItems:
                                          _vm.computedAutocompleteItems,
                                        sendButton: false,
                                      },
                                      on: {
                                        tagInput: _vm.initItems,
                                        tagsChanged: _vm.tagsChanged,
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.tags.length > 0 &&
                                              !_vm.tagsValid,
                                            expression:
                                              "tags.length > 0 && !tagsValid",
                                          },
                                        ],
                                        staticClass: "text-danger text-sm",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "adicione-somente-alunos-cadastrados-ou-e-mail-valido"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-span-10 md:col-span-2" },
                                  [
                                    _c(
                                      "vs-button",
                                      {
                                        staticClass: "footer-button",
                                        attrs: {
                                          type: "filled",
                                          disabled:
                                            _vm.tags.length === 0 ||
                                            !_vm.tagsValid,
                                        },
                                        on: { click: _vm.inviteStudents },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("convidar")))]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "vx-row flex items-end mt-8" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col sm:w-1/1 w-full mb-2",
                                  },
                                  [
                                    _c("h4", [
                                      _vm._v(
                                        _vm._s(_vm.$t("alunos-convidados"))
                                      ),
                                    ]),
                                    _c(
                                      "vs-table",
                                      {
                                        attrs: { data: _vm.inviteList },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var data = ref.data
                                                return _vm._l(
                                                  data,
                                                  function (tr, i) {
                                                    return _c(
                                                      "vs-tr",
                                                      { key: _vm.gridKey },
                                                      [
                                                        _c("vs-td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.studentName(
                                                                data[i]
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("vs-td", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                data[i]
                                                                  .autoSubscribed ||
                                                                  !_vm.isEmpty(
                                                                    data[i]
                                                                      .accepted_at
                                                                  )
                                                                  ? "Confirmado"
                                                                  : "Aguardando confirmação"
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                        _c(
                                                          "vs-td",
                                                          [
                                                            _c("feather-icon", {
                                                              attrs: {
                                                                icon: "Trash2Icon",
                                                                svgClasses:
                                                                  "h-5 w-5 mb-1 mr-3 stroke-current text-danger cursor-pointer",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.unsubscribeConfirmation(
                                                                      data[i]
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                )
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2369813627
                                        ),
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "thead" },
                                          [
                                            _c(
                                              "vs-th",
                                              { attrs: { width: "60%" } },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Student"))
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "vs-th",
                                              { attrs: { width: "20%" } },
                                              [_vm._v(_vm._s(_vm.$t("status")))]
                                            ),
                                            _c(
                                              "vs-th",
                                              { attrs: { width: "20%" } },
                                              [_vm._v(_vm._s(_vm.$t("acao")))]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("nova-disciplina"),
            fullscreen: "false",
            active: _vm.showDisciplineForm,
          },
          on: {
            "update:active": function ($event) {
              _vm.showDisciplineForm = $event
            },
          },
        },
        [
          _vm.showDisciplineForm
            ? _c("DisciplineForm", {
                attrs: { "z-index": "", inlineForm: "true" },
                on: {
                  created: function ($event) {
                    _vm.setDiscipline.apply(void 0, arguments)
                    _vm.showDisciplineForm = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("selecione-a-disciplina"),
            active: _vm.showDisciplineGrid,
          },
          on: {
            "update:active": function ($event) {
              _vm.showDisciplineGrid = $event
            },
          },
        },
        [
          _vm.showDisciplineGrid
            ? _c("Grid", {
                attrs: {
                  service: _vm.disciplineService,
                  route_name: "disciplines",
                  hide_actions: "true",
                  delegate: {
                    itemSelected: function () {
                      return _vm.handleDisciplineSelection
                    },
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "gridThead",
                      fn: function () {
                        return [
                          _c("vs-th", { key: "id" }, [_vm._v("ID")]),
                          _c("vs-th", { key: "title" }, [
                            _vm._v(_vm._s(_vm.$t("classes_title"))),
                          ]),
                          _c("vs-th", { key: "course" }, [
                            _vm._v(_vm._s(_vm.$t("course"))),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "gridData",
                      fn: function (props) {
                        return _vm._l(props.data, function (tr, indextr) {
                          return _c(
                            "vs-tr",
                            { key: indextr, attrs: { data: tr } },
                            [
                              _c(
                                "vs-td",
                                { key: "id", attrs: { width: "5%" } },
                                [
                                  _c("div", {
                                    directives: [
                                      {
                                        name: "html-safe",
                                        rawName: "v-html-safe",
                                        value: tr.id,
                                        expression: "tr.id",
                                      },
                                    ],
                                  }),
                                ]
                              ),
                              _c(
                                "vs-td",
                                { key: "title", attrs: { width: "65%" } },
                                [
                                  _c("div", {
                                    directives: [
                                      {
                                        name: "html-safe",
                                        rawName: "v-html-safe",
                                        value: _vm.shortTitle(tr.title),
                                        expression: "shortTitle(tr.title)",
                                      },
                                    ],
                                  }),
                                ]
                              ),
                              _c(
                                "vs-td",
                                { key: "course", attrs: { width: "30%" } },
                                [
                                  _vm._v(
                                    _vm._s(tr.course ? tr.course.name : "")
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        })
                      },
                    },
                  ],
                  null,
                  false,
                  102524498
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }