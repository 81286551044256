import Rest from '../Rest'

export default class ClassDisciplineService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/class_disciplines'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  getFirstClassDiscipline(classId: Number) {
    return this.get(`/class_discipline/${classId}/first_class_discipline`)
  }
  
}
