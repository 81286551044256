<template>
  <vx-card :title="id === null ? $t('classes_create') : $t('classes_edit')">
    <PlanUsageAlert target="classes" v-if="id === null"/>
    <vs-tabs :color="colorx">
      <vs-tab :label="$t('classes')">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-input
              class="w-full required"
              :label="$t('classes_title')"
              v-model="title"
              name="title"/>
            <span class="text-danger text-sm" v-show="errors.has('title')">{{
              errors.first('title')
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              :label="$t('description')"
              v-model="description"
              name="description"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('description')"
              >{{ errors.first('description') }}</span
            >
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vx-input-group>
              <vs-input
                class="required"
                disabled
                :value="discipline.title"
                :label="$t('discipline')"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('discipline_id')"
                >{{ errors.first('discipline_id') }}</span
              >

              <template slot="append">
                <div class="append-text btn-addon flex items-end">
                  <vs-button
                    color="primary"
                    type="border"
                    icon="search"
                    :title="$t('buscar')"
                    @click="showDisciplineGrid = true"
                  ></vs-button>
                  <vs-button
                    v-permission="'classes.discipline.create'"
                    color="primary"
                    type="border"
                    icon="add"
                    :title="$t('incluir')"
                    @click="showDisciplineForm = true"
                  ></vs-button>
                  <vs-button
                    v-permission="'classes.discipline.clear'"
                    color="primary"
                    type="border"
                    icon="clear"
                    :title="$t('action.clear')"
                    @click="setDiscipline(undefined, '')"
                  ></vs-button>
                </div>
              </template>
            </vx-input-group>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label v-tooltip="{
              content: `<div style='max-width: 200px'>Permite que um aluno se inscreva nesta turma utilizando o código da turma, caso contrário ele poderá se inscrever somente se for convidado através de um link que ele receberá por email.</div>`,
              delay: { show: 500, hide: 100 }
            }">
              {{ $t('permitir-inscricao-com-codigo-da-turma') }}
            </label>
            <br />
            <vs-radio v-model="allow_all" vs-name="allow_all_radio" :vs-value="true" class="mt-2">
              {{ $t('sim') }}
            </vs-radio>
            <vs-radio v-model="allow_all" vs-name="allow_all_radio" :vs-value="false" class="ml-4 mt-2">
              {{ $t('nao') }}
            </vs-radio>
            <span
              class="text-danger text-sm"
              v-show="errors.has('allow_all')"
              >{{ errors.first('allow_all') }}</span
            >
          </div>
          <div class="vx-col w-full mt-4">
            <fieldset
              v-tooltip.top="{
                content: 'O que mostrar ao aluno na tela de detalhamento da turma.',
                delay: { show: 1000, hide: 100 }
              }"
              class="p-2"
            >
              <legend>{{ $t('detalhamento-da-turma') }}</legend>
              <div class="flex flex-row gap-2">
                <vs-checkbox v-model="show_teachers">{{ $t('listar-professores') }}</vs-checkbox>
                <vs-checkbox v-model="show_students">{{ $t('listar-alunos') }}</vs-checkbox>
              </div>
            </fieldset>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-button class="float-right" type="border" @click="cancel"
              >{{ $t('action.cancel') }}</vs-button
            >
            <vs-button
              v-permission="'classes.create'"
              :disabled="!validateForm"
              class="float-right mr-2"
              @click="create"
              >{{ $t('action.save') }}</vs-button
            >
          </div>
        </div>
      </vs-tab>
      <vs-tab :label="$t('students')" v-if="id !== null">
        <div class="vx-row mb-6" style="padding-top: 30px">
          <div class="vx-col w-full">
            <vx-card>
              <div class="vx-row flex items-center">
                <div class="vx-col sm:w-3/12 w-full">
                  <label> <b>{{ $t('codigo-convite') }}</b> {{ invite_code }} </label>
                </div>

                <div class="vx-col sm:w-9/12 w-full">
                  <vs-button
                    type="filled"
                    icon="content_copy"
                    class="footer-button"
                    v-clipboard:copy="invite_code"
                    v-clipboard:success="onSucceddCopy"
                    v-clipboard:error="onErrorCopy"
                    >{{ $t('copiar') }}</vs-button
                  >
                </div>
              </div>
            </vx-card>

            <vx-card :title="$t('convidar-alunos')" class="mt-4">
              <div class="grid grid-cols-10 w-full gap-2">
                <div class="fixAutoComplete col-span-10 md:col-span-8">
                  <emails-input
                    @tagInput="initItems"
                    @tagsChanged="tagsChanged"
                    :validation="[
                      {
                        classes: 'valid-email-or-user',
                        rule: ({ student_id, text }) =>
                          !this.$utils.validation.email(text) && this.isEmpty(student_id)
                      }
                    ]"
                    :autocompleteItems="computedAutocompleteItems"
                    :sendButton="false" >
                  </emails-input>
                  <span
                      class="text-danger text-sm"
                      v-show="tags.length > 0 && !tagsValid"
                    >
                      {{ $t('adicione-somente-alunos-cadastrados-ou-e-mail-valido') }}
                  </span>
                </div>
                <div class="col-span-10 md:col-span-2">
                  <vs-button
                    type="filled"
                    class="footer-button"
                    @click="inviteStudents"
                    :disabled="tags.length === 0 || !tagsValid"
                    >{{ $t('convidar') }}</vs-button
                  >
                </div>
              </div>

              <div class="vx-row flex items-end mt-8">
                <div class="vx-col sm:w-1/1 w-full mb-2">
                  <h4>{{ $t('alunos-convidados') }}</h4>
                  <vs-table :data="inviteList">
                    <template slot="thead">
                      <vs-th width="60%">{{ $t('Student') }}</vs-th>
                      <vs-th width="20%">{{ $t('status') }}</vs-th>
                      <vs-th width="20%">{{ $t('acao') }}</vs-th>
                    </template>

                    <template slot-scope="{ data }">
                      <vs-tr :key="gridKey" v-for="(tr, i) in data">
                        <vs-td>{{ studentName(data[i]) }}</vs-td>
                        <vs-td>
                          {{
                            data[i].autoSubscribed ||
                            !isEmpty(data[i].accepted_at)
                              ? 'Confirmado'
                              : 'Aguardando confirmação'
                          }}
                        </vs-td>
                        <vs-td>
                          <feather-icon
                            icon="Trash2Icon"
                            svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger cursor-pointer"
                            @click="unsubscribeConfirmation(data[i])"
                          />
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
              </div>
            </vx-card>
          </div>
        </div>
      </vs-tab>
    </vs-tabs>

    <vs-popup
      :title="$t('nova-disciplina')"
      fullscreen="false"
      :active.sync="showDisciplineForm"
    >
      <DisciplineForm
        z-index
        v-if="showDisciplineForm"
        inlineForm="true"
        @created="
          setDiscipline(...arguments)
          showDisciplineForm = false
        "
      ></DisciplineForm>
    </vs-popup>

    <vs-popup :title="$t('selecione-a-disciplina')" :active.sync="showDisciplineGrid">
      <Grid
        :service="disciplineService"
        route_name="disciplines"
        hide_actions="true"
        :delegate="{itemSelected: () => handleDisciplineSelection} "
        v-if="showDisciplineGrid"
      >
        <template v-slot:gridThead>
          <vs-th key="id">ID</vs-th>
          <vs-th key="title">{{ $t('classes_title') }}</vs-th>
          <vs-th key="course">{{ $t('course') }}</vs-th>
        </template>
        <template v-slot:gridData="props">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
            <vs-td width="5%" key="id">
              <div v-html-safe="tr.id"></div>
            </vs-td>
            <vs-td width="65%" key="title">
              <div v-html-safe="shortTitle(tr.title)"></div>
            </vs-td>
            <vs-td width="30%" key="course">{{
              tr.course ? tr.course.name : ''
            }}</vs-td>
          </vs-tr>
        </template>
      </Grid>
    </vs-popup>
  </vx-card>
</template>

<script>
import EmailsInput from '@/components/EmailsInput'
import ClassService from '@/services/api/ClassService'
import DisciplineService from '@/services/api/DisciplineService'
import ClassDisciplineService from '@/services/api/ClassDisciplineService'
import ClassStudentService from '@/services/api/ClassStudentService'
import StudentService from '@/services/api/StudentService'
import DisciplineForm from '../disciplines/Form'
import FirebaseChatService from '@/services/firebase/FirebaseChatService'
import User from '@/chat/firestore/models/User'
import PlanUsageAlert from '@/components/finance/PlanUsageAlert'

export default {
  components: {
    EmailsInput,
    DisciplineForm,
    PlanUsageAlert
  },
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data: function() {
    return {
      title: '',
      description: '',
      invite_code: '',
      classDiscipline: null,
      allow_all: false,
      firstClassDiscipline: null,
      discipline: {
        title: '',
        id: null
      },
      inviteList: [],
      tags: [],
      autocompleteItems: [],
      showModal: false,
      showDisciplineGrid: false,
      showDisciplineForm: false,
      disciplineService: null,
      classService: null,
      classDisciplineService: null,
      classStudentService: null,
      gridKey: 0,
      tagsValid: false,
      firebaseChatService: null,
      show_students: true,
      show_teachers: true
    }
  },
  computed: {
    delegateClassDiscipline() {
      return {
        destroy: this.destroyClassDiscipline
      }
    },
    validateForm() {
      return (
        !this.isEmpty(this.title) &&
        !this.isEmpty(this.discipline.title) &&
        !this.isEmpty(this.discipline.id)
      )
    },
    computedAutocompleteItems() {
      return this.autocompleteItems
    }
  },
  methods: {
    invitateValidation() {
      return [
        {
          classes: 'valid-email-or-user',
          rule: ({ student_id, text }) => {
            return !this.$utils.validation.email(text) && this.isEmpty(student_id)
          }
        }
      ]
    },
    inviteStudents() {
      this.$vs.loading()
      this.classService.inviteStudents(this.classDiscipline.id, this.tags).then(
        data => {
          if (data.length < this.tags.length) {
            this.notifyWarning(
              this.$vs,
              this.$t('alunos-ja-convidados-nao-serao-convidados-novamente'),
              4000,
              'top-center'
            )
          }

          data.forEach(element => {
            this.inviteList.push(element)
          })
          this.tags = []
          this.gridKey++
          this.$vs.loading.close()
          this.notifySuccess(this.$vs)
        },
        error => {
          this.$vs.loading.close()
          this.showErrors(this.$validator, error)
        }
      )
    },
    tagsChanged(newTags, validity) {
      this.autocompleteItems = []
      this.tagsValid = validity
      this.tags = newTags
    },
    initItems(tag) {
      const items = []

      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        if (!this.isEmpty(tag)) {
          this.studentService.searchByName(tag).then(response => {
            this.autocompleteItems = response.map(student => {
              return {
                text: `${student.user_name} (${student.user_email})`,
                student_id: student.id
              }
            })
          })
        }
      }, 600)

      return items
    },
    setDiscipline() {
      if (arguments.length === 2) {
        this.discipline.id = arguments[0]
        this.discipline.title = arguments[1]
      } else {
        this.discipline.id = null
        this.discipline.title = ''
      }
    },
    shortTitle(title) {
      return this.truncate(this.html2text(title), 100)
    },
    handleDisciplineSelection(discipline) {
      this.discipline.id = discipline.id
      this.discipline.title = discipline.title
      this.showDisciplineGrid = false
    },
    create() {
      this.clearErrors(this.$validator)
      this.$vs.loading()

      this.classService
        .createOrUpdateExtended({
          id: this.id,
          description: this.description,
          title: this.title,
          discipline_id: this.discipline.id,
          allow_all: this.allow_all,
          show_students: this.show_students,
          show_teachers: this.show_teachers
        })
        .then(
          data => {
            this.$vs.loading.close()
            this.notifySuccess(this.$vs)
            this.classDiscipline = data.classDiscipline
            this.invite_code = data.class.invite_code

            // Create firebase chat room
            try {
              this.createFirebaseClassChat(data)
            } catch (error) {
              const type = this._.get(error, 'type')
              const name = this._.get(error, 'name')
              const message = this._.get(error, 'message')

              this.$exceptionService.quickRegister(
                this.$t('erro-firebase'),
                'javascript_general',
                {
                  type: type,
                  name: name,
                  message: message,
                }
              )
            }

            if (this.isEmpty(this.id)) {
              this.$router.push(`/classes/${data.class.id}/edit`)
            }
          },
          error => {
            this.$vs.loading.close()
            this.showErrors(this.$validator, error)
          }
        )
    },
    createFirebaseClassChat(data) {
      const firstUser = User.fromDBUser(this.$store.getters.AppActiveUser)

      this.firebaseChatService.storeRoom({
        roomName: this.$t('chat-data-class-title', [data.class.title]),
        description: this.$t('grupo-de-chat-da-turma-data-class-title', [data.class.title]),
        users: [firstUser],
        type: {
          name: 'class',
          value: data.class.id
        }
      }).then(
        data => {
          this.$vs.loading.close()
        },
        error => {
          const type = this._.get(error, 'type')
          const name = this._.get(error, 'name')
          const message = this._.get(error, 'message')

          this.$exceptionService.quickRegister(
            this.$t('erro-firebase-0'),
            'javascript_general',
            {
              type: type,
              name: name,
              message: message,
            }
          )
        }
      )
    },
    cancel() {
      this.$router.push('/classes')
    },
    getData(id) {
      this.$vs.loading()
      this.classService.read(id).then(
        data => {
          this.id = data.id
          this.title = data.title
          this.description = data.description
          this.invite_code = data.invite_code
          this.allow_all = data.allow_all ? data.allow_all : false
          this.show_students = data.show_students
          this.show_teachers = data.show_teachers

          this.firstClassDiscipline = this.getFirstClassDiscipline(
            data.class_disciplines
          )
          if (!this.isEmpty(this.firstClassDiscipline)) {
            this.classDiscipline = this.firstClassDiscipline
            this.$set(this.$data, 'discipline', {
              title: this.firstClassDiscipline.discipline.title,
              id: this.firstClassDiscipline.discipline_id
            })
            this.createList()
          }
          this.$vs.loading.close()
        },
        error => {
          this.$vs.loading.close()
        }
      )
    },
    createList() {
      const classStudentList = this.firstClassDiscipline.class_students
      classStudentList.forEach(element => {
        element.autoSubscribed = true
      })

      this.firstClassDiscipline.invites.forEach(invite => {
        const classStudent = classStudentList.find(classStudent => {
          if (this.isEmpty(classStudent.student)) {
            return false
          }
          if (!this.isEmpty(invite.student_id)) {
            return classStudent.student.id === invite.student_id
          } else if (!this.isEmpty(invite.email)) {
            return classStudent.student.person.user.email === invite.email
          }
          return true
        })

        if (this.isEmpty(classStudent)) {
          classStudentList.push(invite)
        }
      })

      this.inviteList = classStudentList
    },
    getFirstClassDiscipline(classDisciplines, disciplineId) {
      return classDisciplines ? classDisciplines[0] : null
    },
    onSucceddCopy() {
      this.notifySuccess(this.$vs, `${this.$t('copiado')}!`, 2000, 'center-top')
    },
    onErrorCopy() {
      this.notifyError(this.$vs, this.$t('nao-foi-possivel-copiar'))
    },
    studentName(element) {
      return element.student_id !== null
        ? `${element.student.person.user.name} - ${element.student.person.user.email}`
        : element.email
    },
    unsubscribeConfirmation(element) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim-enviar'),
        cancelText: this.$t('nao'),
        text: this.$t('tem-certeza-que-remover-este-aluno-da-turma'),
        accept: () => {
          this.unsubscribe(element)
        }
      })
    },
    unsubscribe(element) {
      this.$vs.loading()

      const classDisciplineId = element.class_discipline_id
      const studentId = element.student_id
      const email = this.isEmpty(element.student_id) ? element.email : null

      const params = {
        class_discipline_id: classDisciplineId,
        student_id: studentId,
        email: email
      }

      this.classService.unsubscribeClass(params).then(
        response => {
          this.inviteList = this.inviteList.filter(invite => {
            return invite.id !== element.id
          })

          this.$vs.loading.close()
          this.notifySuccess(this.$vs)
        },
        error => {
          this.$vs.loading.close()
        }
      )
    }
  },
  created() {
    this.classService = ClassService.build(this.$vs)
    this.disciplineService = DisciplineService.build(this.$vs)
    this.classDisciplineService = ClassDisciplineService.build(this.$vs)
    this.classStudentService = ClassStudentService.build(this.$vs)
    this.studentService = StudentService.build(this.$vs)
    this.firebaseChatService = FirebaseChatService.build(this)
    this.firebaseChatService.initialize()
    if (this.id) {
      this.getData(this.id)
    }
  }
}
</script>

<style>
.vue-tags-input {
  max-width: 100% !important;
}

.fixAutoComplete .vue-tags-input{
  position: static !important;
}

.ti-autocomplete {
  z-index: 9999999999999 !important;
  overflow-x: auto;
}

.valid-email-or-user {
  color: red;
}
</style>
